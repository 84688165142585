import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import ErrorMessage from './common/ErrorMessage.js';

const PostLink = props => {
    if (!props.slug) {
        return <span></span>;
    }
    if (props.title) {
        return <Link to={`/blog/${props.slug}`}>{props.title}</Link>;
    }
    return (
        <Link to={`/blog/${props.slug}`}>
            <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 194.818 194.818">
                <title>External</title>
                <g>
                    <path d="M185.818,2.161h-57.04c-4.971,0-9,4.029-9,9s4.029,9,9,9h35.312l-86.3,86.3c-3.515,3.515-3.515,9.213,0,12.728 c1.758,1.757,4.061,2.636,6.364,2.636s4.606-0.879,6.364-2.636l86.3-86.3v35.313c0,4.971,4.029,9,9,9s9-4.029,9-9v-57.04 C194.818,6.19,190.789,2.161,185.818,2.161z"></path>
                    <path d="M149,77.201c-4.971,0-9,4.029-9,9v88.456H18v-122h93.778c4.971,0,9-4.029,9-9s-4.029-9-9-9H9c-4.971,0-9,4.029-9,9v140 c0,4.971,4.029,9,9,9h140c4.971,0,9-4.029,9-9V86.201C158,81.23,153.971,77.201,149,77.201z"></path>
                </g>
            </svg>
        </Link>
    );
};
PostLink.propTypes = {
    slug: PropTypes.string,
    title: PropTypes.string
};

const BlogPostList = props => {
    const { error, errorCode, data, isLoading } = props;
    if (isLoading) {
        return (
            <div className="loading">
                <div className="loading-content">
                    <div className="stripe long-stripe"></div>
                    <div className="stripe long-stripe"></div>
                    <div className="stripe long-stripe"></div>
                    <div className="stripe long-stripe"></div>
                    <div className="stripe long-stripe"></div>
                    <div className="stripe long-stripe"></div>
                    <div className="stripe long-stripe"></div>
                    <div className="stripe long-stripe"></div>
                    <div className="stripe long-stripe"></div>
                    <div className="stripe long-stripe"></div>
                    <div className="stripe long-stripe"></div>
                    <div className="stripe long-stripe"></div>
                    <div className="stripe long-stripe"></div>
                    <div className="stripe long-stripe"></div>
                    <div className="stripe long-stripe"></div>
                    <div className="stripe long-stripe"></div>
                    <div className="stripe long-stripe"></div>
                </div>
            </div>
        );
    } else if (data) {
        if (isEmpty(data)) {
            return <span></span>;
        }
        return (
            <div className="blog-posts-container">
                <table className="blog-posts-table">
                    <thead>
                        <tr>
                            <th>Published</th>
                            <th>Title</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((post, index) => {
                            return (
                                <tr key={index}>
                                    <td className="overline published">
                                        {new Date(post.published_at).toLocaleDateString()}
                                    </td>
                                    <td className="title">
                                        <PostLink slug={post.slug} title={post.title} />
                                    </td>
                                    <td className="links">
                                        <span>
                                            <PostLink slug={post.slug} />
                                        </span>
                                    </td>
                                </tr>
                            );
                        }, this)}
                    </tbody>
                </table>
            </div>
        );
    } else {
        return <ErrorMessage errorCode={errorCode} error={error} />;
    }
};
BlogPostList.propTypes = {
    errorCode: PropTypes.number,
    error: PropTypes.string,
    data: PropTypes.array,
    isLoading: PropTypes.bool
};
export default BlogPostList;
